import React from 'react';
import style from './App.module.scss';
import audio from './avani_you_fool_final.mp3';



class App extends React.Component {
  playAudio = () => {
    new Audio(audio).play();
  }

  render(){
    return (
      <div className={style.Container}>
        <div className={style.Modal}>
        <div
          className={style.SelectButton}
          onClick={this.playAudio.bind(this)}></div>
        <div className={style.HintText}>Turn up your volume!</div>
        </div>
      </div>
    );
  }
}

export default App;
